<template>
  <div class="h-full flex-grow pb-40 lg:pb-36">
    <div class="h-full overflow-auto pb-6">
      <div class="container row-auto grid grid-cols-2 gap-2 md:grid-cols-3">
        <figure
          v-for="(image, imageIndex) in images"
          :key="image.id"
          :class="{
            'col-span-2 row-span-2 md:h-[31.125rem]': imageIndex === 0,
            'aspect-square md:aspect-auto md:h-[15.3125rem]': imageIndex > 0,
          }"
        >
          <button
            class="block size-full cursor-pointer overflow-clip"
            @click="onImageClick(imageIndex)"
          >
            <NuxtImg
              :src="image.filename"
              :alt="image.alt"
              class="ease-out-expo size-full object-cover transition-transform duration-700 hover:scale-105"
              width="840"
              loading="lazy"
            />
          </button>
        </figure>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MultiassetStoryblok } from '@/types/storyblok'

defineProps<{
  images: MultiassetStoryblok
}>()

const emit = defineEmits<{
  onImageClick: [index: number]
}>()

function onImageClick(index: number) {
  emit('onImageClick', index)
}
</script>
