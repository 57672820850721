<template>
  <div
    class="c-carousel absolute inset-0 flex flex-grow items-center justify-center"
  >
    <div
      :class="{ 'container h-full': isContained, 'size-full': !isContained }"
    >
      <div
        v-if="showControls"
        class="absolute left-6 top-1/2 z-10 hidden -translate-y-1/2 transform md:block"
      >
        <UiMediaControl
          label="Previous"
          icon="arrow-left"
          size="sm"
          @click="onPrevious"
        />
      </div>

      <div
        v-if="showControls"
        class="absolute right-6 top-1/2 z-10 hidden -translate-y-1/2 transform md:block"
      >
        <UiMediaControl
          label="Next"
          icon="arrow-right"
          size="sm"
          @click="onNext"
        />
      </div>

      <Swiper
        :modules="[Pagination, A11y]"
        :slides-per-view="1"
        :pagination="{ clickable: true }"
        :loop="true"
        :keyboard="true"
        class="size-full"
        @swiper="onSwiper"
      >
        <SwiperSlide v-for="image in images" :key="image.id" class="w-screen">
          <figure class="flex size-full items-center justify-center">
            <NuxtImg
              :src="image.filename"
              :alt="image.alt"
              :class="{
                'pb-20': hasBottomGap,
              }"
              sizes="sm:100vw md:1920px"
              class="max-h-screen pt-20"
            />
          </figure>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Swiper } from 'swiper'
import { Pagination, A11y } from 'swiper/modules'
import type { MultiassetStoryblok } from '@/types/storyblok'

const props = defineProps<{
  images: MultiassetStoryblok
  selectedImageIndex: number
  isContained?: boolean
  hasBottomGap?: boolean
}>()
const swiperInstance = ref()
const showControls = computed(() => props.images.length > 1)

function onSwiper(swiper: Swiper): void {
  swiperInstance.value = swiper
  swiperInstance.value.slideTo(props.selectedImageIndex)
}

function onPrevious(): void {
  swiperInstance.value?.slidePrev()
}

function onNext(): void {
  swiperInstance.value?.slideNext()
}
</script>

<style scoped lang="postcss">
.c-carousel :deep(.swiper-pagination) {
  @apply absolute bottom-32 left-1/2 z-50 flex h-6 w-auto -translate-x-1/2 transform items-center justify-center gap-1.5 rounded-2xl bg-white/10 px-3 lg:bottom-6 lg:bg-black/50;

  .swiper-pagination-bullet {
    @apply ease-out-expo lg:bg-tertiary m-0 h-2 w-2 bg-gray-500 opacity-100 transition-all duration-700 hover:bg-white;

    &-active {
      @apply scale-150 bg-white;
    }
  }
}
</style>
