<template>
  <Modal :is-open="isOpen" @close="onModalClose">
    <header
      :class="{
        'lg:h-36': layoutMode === 'grid',
      }"
      class="ease-out-expo relative z-10 h-20 w-full flex-none bg-black py-4 transition-all duration-700"
    >
      <div class="container flex h-full items-center justify-end gap-6">
        <div
          v-if="$slots.title || $slots.subTitle"
          class="flex-grow leading-tight text-white"
        >
          <p v-if="$slots.title" class="text-sm font-bold lg:text-base">
            <slot name="title" />
          </p>
          <p v-if="$slots.subTitle" class="text-xs lg:text-sm">
            <slot name="subTitle" />
          </p>
        </div>

        <div v-if="showLayoutModeControls" class="flex items-center gap-2">
          <button
            :class="{
              'is-active': layoutMode === 'grid',
            }"
            class="c-modal-action"
            @click="setMode('grid')"
          >
            <span class="sr-only">Go to grid view mode</span>
            <UiIcon icon="grid" size="md" />
          </button>

          <button
            :class="{
              'is-active': layoutMode === 'fullscreen',
            }"
            class="c-modal-action"
            @click="setMode('fullscreen')"
          >
            <span class="sr-only">Go to fullscreen mode</span>
            <UiIcon icon="fullscreen" size="md" />
          </button>
        </div>

        <slot name="headerActions" />

        <button class="c-modal-action" @click="onModalClose">
          <span class="sr-only">Close photo gallery</span>
          <UiIcon icon="close" size="md" />
        </button>
      </div>
    </header>

    <PhotoGalleryGrid
      v-if="layoutMode === 'grid'"
      :images="images"
      @on-image-click="onImageClick"
    />

    <Transition name="fade">
      <PhotoGalleryFullscreen
        v-if="layoutMode === 'fullscreen'"
        :images="images"
        :selected-image-index="selectedImageIndex"
        :is-contained="isContained"
        :has-bottom-gap="hasBottomGap"
      />
    </Transition>

    <footer
      v-if="$slots.footerActions"
      class="absolute bottom-0 left-0 z-10 h-20 w-full flex-none bg-black lg:hidden"
    >
      <div class="container flex h-full items-center justify-center gap-3">
        <slot name="footerActions" />
      </div>
    </footer>
  </Modal>
</template>

<script setup lang="ts">
import type {
  BlockGalleryStoryblok,
  MultiassetStoryblok,
} from '@/types/storyblok'

defineSlots<{
  footerActions(): HTMLElement
  headerActions(): HTMLElement
  subTitle(): HTMLElement
  title(): HTMLElement
}>()

const props = defineProps<{
  isOpen: boolean
  isContained?: boolean
  images: MultiassetStoryblok
  galleryStyle?: BlockGalleryStoryblok['galleryStyle']
  hasBottomGap?: boolean
}>()
const initialLayoutMode = computed(() => {
  if (props.galleryStyle) {
    return props.galleryStyle === 'grid' ? 'grid' : 'fullscreen'
  }

  return props.images.length >= 3 ? 'grid' : 'fullscreen'
})
const showLayoutModeControls = computed(() => props.images.length >= 2)
const layoutMode = ref<LayoutMode>(initialLayoutMode.value)
const selectedImageIndex = ref<number>(0)
const emit = defineEmits<{
  close: []
}>()

function onModalClose(event: Event | KeyboardEvent): void {
  if (
    layoutMode.value === 'fullscreen' &&
    event instanceof KeyboardEvent &&
    event.key === 'Escape'
  ) {
    if (props.galleryStyle === 'default') {
      emit('close')
      return
    }

    if (showLayoutModeControls.value) {
      setMode('grid')
      return
    }
  }

  emit('close')
}

function setMode(mode: LayoutMode, index?: number): void {
  if (mode === layoutMode.value) {
    return
  }

  layoutMode.value = mode
  selectedImageIndex.value = index ?? 0
}

function onImageClick(index: number): void {
  setMode('fullscreen', index)
}

type LayoutMode = 'grid' | 'fullscreen'
</script>

<style scoped lang="postcss">
.c-modal-action {
  @apply ease-out-expo text-white text-opacity-60 transition-colors duration-700 hover:text-opacity-100;

  &.is-active {
    @apply text-opacity-100;
  }
}
</style>
